import { useEffect, useState } from "react";
import Welcome from "./components/Welcome";
import db from "./data/songs.json";
import getGameIndexofToday from "./functions/num_of_days_from_date";

function App() {
  const [solution, setSolution] = useState(null);
  const [split, setSplit] = useState(null);
  const [album, setAlbum] = useState(null);

  const game_id = getGameIndexofToday(); // 1-infinity. Game #1's solution will be songs[0]

  function setValues(songs) {
    const song_id = (game_id-1) % songs.length; // so first game will have first solution in the array.
    const todaysSolution = songs[song_id];
    setSolution(todaysSolution.song_title.toLowerCase());
    setSplit(JSON.parse(todaysSolution.split));
    setAlbum(todaysSolution.album_title);
  }
  
  //localStorage.removeItem("wordlestruckLastGamePlayed");
  //localStorage.removeItem("statistics");
  useEffect(() => {
    let songs;
    fetch('https://wordlestruck-dataset-210389.netlify.app/songs.json')
    .then((response) => response.json())
    .then((data) => {
      // Handle the retrieved data
      songs = data;
      setValues(songs);
    })
    .catch((error) => {
      // Handle any errors
      console.log(error);
      songs = db;
      setValues(songs);
    });
  }, [game_id]);

  return (
    <div className="App">
      {solution && (
        <Welcome
          gameNo={game_id}
          solution={solution}
          split={split}
          album={album}
        />
      )}
    </div>
  );
}

export default App;
