import React from 'react'

export default function Instructions({ setShowModal }) {
  return (
    <div className="modal" id="instructions-modal">
      <div
        className="in-modal"
        style={{ textAlign: "left", fontFamily: "Times New Roman" }}
      >
        <span className="close" onClick={() => setShowModal(false)}>
          &times;
        </span>
        <h2 className="landing-title">How To Play</h2>
        <h3>Guess the name of a Taylor Swift song in 6 tries.</h3>
        <li>
          The solution includes only letters:
          <br />
          <span style={{ color: "red" }}>&emsp;&emsp;X </span>"Don't", "3"
          <br />
          <span style={{ color: "green" }}>&emsp;&emsp;V </span>"Dont",
          "Three"
        </li>
        <li>
          The color of the tiles will change to show how close your guess was to
          the word.
        </li>
        <p>
          <b>Examples:</b>
        </p>
        <img
          className="modal-image"
          src="instructions_green.png"
          alt="instructions_green image"
        />
        <p>
          <b>A</b> is in the word and in the correct spot. The rest of the
          letters do not appear in the word.
        </p>
        <img
          className="modal-image"
          src="instructions_yellow.png"
          alt="instructions_yellow image"
        />
        <p>
          <b>L</b> is in the word but in the wrong spot. The rest of the letters
          do not appear in the word.
        </p>
        <div className="horizontal-line"></div>
        <p>A new puzzle is released daily at midnight.</p>
      </div>
    </div>
  );
}
