import React from 'react'

export default function Keypad({usedKeys, handleKeyupVirtual, isLocked}) {
    const pointerEvents = isLocked ? "none" : "auto"
    const topRow = ["q","w","e","r","t","y","u","i","o","p"]
    const middleRow = ["a","s","d","f","g","h","j","k","l"]
    const bottomRow = ["z","x","c","v","b","n","m"]

  return (
    <div className='keypad'>
        <div className='keypad-row'>
            {topRow.map((letter) => {
                const color = usedKeys[letter]
                return (
                    <div key={letter} className={color} style={{pointerEvents: pointerEvents}}
                    onClick={() => handleKeyupVirtual(letter)}>{letter}</div>
                )
            })}
        </div>
        <div className='keypad-row'>
            <div style={{flex: "0.5", backgroundColor: "transparent", cursor: "default"}}></div>
            {middleRow.map((letter) => {
                const color = usedKeys[letter]
                return (
                    <div key={letter} className={color} style={{pointerEvents: pointerEvents}}
                    onClick={() => handleKeyupVirtual(letter)}>{letter}</div>
                )
            })}
            <div style={{flex: "0.5", backgroundColor: "transparent", cursor: "default"}}></div>
         </div>
        <div className='keypad-row'>
            <div key="Enter" style={{flex: "1.5", fontSize:"13px"}}
                    onClick={() => handleKeyupVirtual("Enter")}>Enter</div>
            {bottomRow.map((letter) => {
                const color = usedKeys[letter]
                return (
                    <div key={letter} className={color} style={{pointerEvents: pointerEvents}}
                    onClick={() => handleKeyupVirtual(letter)}>{letter}</div>
                )
            })}
            <div key="Backspace" style={{flex: "1.5", textAlign:"center", whiteSpace: "nowrap", pointerEvents: pointerEvents}}
                    onClick={() => handleKeyupVirtual("Backspace")}>
                        <span className="helper"></span>
                        <img src="backspace-icon.png" alt="backspace icon" style={{width:"20px", verticalAlign:"middle", marginBottom:"3px"}}>
                    </img></div>
        </div>
    </div>
  )
}
