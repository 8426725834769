import React, { useEffect, useState } from "react";
import useWordle from "../hooks/useWordle";
import Grid from "./Grid";
import Keypad from "./Keypad";
import ProgressModal from "./ProgressModal";
import Instructions from "./Instructions";

export default function Wordle({
  solution,
  split,
  album,
  showInstructions,
  showProgress,
  gameNo,
}) {
  const solutionNoSpaces = solution.replace(/\s/g, "");
  
  const [shaky, setShaky] = useState(false)
  const { currentGuess, handleKeyup, handleKeyupVirtual, storageData,
  } = useWordle(gameNo, solutionNoSpaces, setShaky);
  const [showProgressModal, setShowProgressModal] = useState(showProgress);
  const [showInstructionsModal, setShowInstructionsModal] =
    useState(showInstructions);
  let turn = storageData.turn
  let isCorrect = storageData.isCorrect
  let usedKeys = storageData.usedKeys
  let guesses = storageData.guesses
  //new name
  if (!localStorage.statistics) {
    localStorage.statistics = JSON.stringify({
      stats:[
        ...Array(7).fill(0),
      ],
      currentStreakLastIndex: 0,
      currentStreak: 0,
      maxStreak: 0
    });
  }
  useEffect(() => {
   
      if (isCorrect || turn > 5) {
        let player_just_won = isCorrect && localStorage.wordlestruckLastGamePlayed && JSON.parse(localStorage.wordlestruckLastGamePlayed).isCorrect===false
        let player_just_lost = turn > 5 && localStorage.wordlestruckLastGamePlayed && JSON.parse(localStorage.wordlestruckLastGamePlayed).turn===5
        //run this block only 1 time
        if(player_just_won || player_just_lost){
          setTimeout(() => setShowProgressModal(true), 2000);
          window.removeEventListener("keyup", handleKeyup);

          
          let objStatistics = JSON.parse(localStorage.statistics);
          let index = isCorrect ? turn : 0;
          objStatistics.stats[index] = Number(objStatistics.stats[index]) + 1;
          objStatistics.currentStreak = objStatistics.currentStreakLastIndex === gameNo - 1? objStatistics.currentStreak + 1: 1;
          objStatistics.maxStreak = objStatistics.currentStreak>objStatistics.maxStreak ? objStatistics.currentStreak : objStatistics.maxStreak
          objStatistics.currentStreakLastIndex = gameNo
          localStorage.statistics = JSON.stringify(objStatistics);

        }
        
      }
      else{
        window.addEventListener("keyup", handleKeyup);
      }
      localStorage.wordlestruckLastGamePlayed = JSON.stringify(storageData);
      return () => window.removeEventListener("keyup", handleKeyup);
    
  }, [handleKeyup, storageData, isCorrect, turn]);

  return (
    <>
      <div className="header-div">
        <h1>
          Wordlestruck
        </h1>

        <img
          className="modal-image"
          src="stats-icon.png"
          alt="show stats"
          style={{ float: "right", paddingRight: "15px", paddingTop: "5px", cursor: "pointer" }}
          onClick={() => setShowProgressModal(true)}
        />
        <img
          className="modal-image"
          src="instructions-icon.png"
          alt="show instructions"
          style={{ float: "right", paddingRight: "5px", paddingTop: "5px", cursor: "pointer" }}
          onClick={() => setShowInstructionsModal(!showInstructionsModal)}
        />
      </div>
      {
      (<div className="same-word-tooltip" >
        <span className="tooltiptext" style={{visibility:shaky? "visible":"hidden"}}>You already tried this guess</span>
      </div>)}
      { (
        <div id="game-container">
          <Grid
            currentGuess={currentGuess}
            guesses={guesses}
            turn={turn}
            length={solutionNoSpaces.length}
            split={split}
            shaky={shaky}
          />
          <Keypad usedKeys={usedKeys} handleKeyupVirtual={handleKeyupVirtual} isLocked={(isCorrect || turn > 5)} />
        </div>
      )}

      {showProgressModal && (
        <ProgressModal
          setShowModal={setShowProgressModal}
          solution={solution}
          album={album}
          gameNo={gameNo}
          split={split}
        />
      )}
      {showInstructionsModal && (
        <Instructions setShowModal={setShowInstructionsModal} />
      )}
    </>
  );
}
