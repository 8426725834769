import React from "react";

export default function Statistics({ gameNo }) {
  let objStatistics = JSON.parse(localStorage.statistics);
  let totalGames = 0;
  objStatistics.stats.forEach((stat) => {
    totalGames += stat;
  });
  let totalWins = totalGames - objStatistics.stats[0];
  // to remove first element - number of loses.
  let gameStatsWithout0 = objStatistics.stats;
  gameStatsWithout0.shift();

  let lastGame,
    turn = 0,
    isCorrect = false,
    //win or lose, doesn't matter, show answer
    gameWasPlayedToday = false;
  if (localStorage.wordlestruckLastGamePlayed) {
    lastGame = JSON.parse(localStorage.wordlestruckLastGamePlayed);
    gameWasPlayedToday = lastGame.gameNo === gameNo ? true : false;
    isCorrect = lastGame.isCorrect;
    turn = isCorrect ? lastGame.turn : 0;
  }

  return (
    <div>
      <h5>Statistics</h5>
      <table className="gameStat">
        <tbody>
          <tr>
            <td style={{width: "25%"}}>
              <div className="gameStatNum">{totalGames}</div>
            </td>
            <td style={{width: "25%"}}>
              <div className="gameStatNum">
                { totalGames!==0 ? Math.floor((totalWins / totalGames) * 100):0}
              </div>
            </td>
            <td style={{width: "25%"}}>
              <div className="gameStatNum">{objStatistics.currentStreak}</div>
            </td>
            <td style={{width: "25%"}}>
              <div className="gameStatNum">
                {objStatistics.maxStreak}
              </div>
            </td>
          </tr>
          <tr style={{verticalAlign: "top"}}>
            <td>
              <div className="gameStatText">Played</div>
            </td>
            <td>
              <div className="gameStatText">Win %</div>
            </td>
            <td>
              <div className="gameStatText">Currect<br/>Streak</div>
            </td>
            <td>
              <div className="gameStatText">Max<br></br>Streak</div>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Guess Distribution</h5>
      <table style={{ width: "100%" }}>
        <tbody>
          {gameStatsWithout0.map((stat, index) => {
            // create index variable to match the new array
            let guessDistIndex = index + 1
              let barWidth =
                stat === 0
                  ? "4"
                  : (Math.floor((stat / totalGames) * 80) + 8).toString();
              let barColor =
              gameWasPlayedToday && isCorrect && (guessDistIndex === turn)
                  ? "green"
                  : "rgb(107, 107, 109)";
              return (
                <tr key={guessDistIndex}>
                  <td>{guessDistIndex}</td>
                  <td>
                    <div
                      className="barChart"
                      style={{
                        width: `${barWidth}%`,
                        backgroundColor: `${barColor}`,
                      }}
                    >
                      {stat}
                    </div>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </table>
    </div>
  );
}
