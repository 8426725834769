import React, { useState } from "react";
import Wordle from "./Wordle";

export default function Welcome({ gameNo, solution, split, album }) {
  const [showWordle, setShowWordle] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  var options = { year: "numeric", month: "long", day: "numeric" };
  var today = new Date();
  var displayDate = today.toLocaleDateString("en-US", options);
  let lastGame, gameWasStartedToday = false, gameWasPlayedToday=false, turn;
  if (localStorage.wordlestruckLastGamePlayed) {
    lastGame = JSON.parse(localStorage.wordlestruckLastGamePlayed);
    gameWasStartedToday = lastGame && lastGame.gameNo === gameNo ? true : false;
    gameWasPlayedToday = gameWasStartedToday && (lastGame.isCorrect||lastGame.turn === 6);
    turn = lastGame.turn;
  }

  function startGame() {
    setShowWordle(true);
  }
  function showInstructionsModal() {
    setShowInstructions(true);
  }
  function showProgressModal() {
    setShowProgress(true);
  }

  return (
    <>
      {!showWordle && !showInstructions && !showProgress && (
        <>
          <div className="landing">
            <div className="in-landing">
              <img
                className="modal-image"
                src="perfume.png"
                alt="Game logo icon"
              />
              <br />
              {!gameWasStartedToday && (
                <>
                  <h1 className="landing-title">Wordlestruck</h1>
                  <h2>
                    Get 6 chances to guess the name of a Taylor Swift song.
                  </h2>
                  <button onClick={showInstructionsModal} className="button">
                    How to play
                  </button>
                  <button onClick={startGame} className="button-black">
                    Play
                  </button>
                </>
              )}
              {gameWasStartedToday && !gameWasPlayedToday && turn>0 &&(
                <>
                  <h1 className="landing-title">Welcome Back</h1>
                  <h2>
                    You're on attempt {turn} out of 6.
                    <br />
                    Keep it up!
                  </h2>
                  <button onClick={startGame} className="button-black">
                    Continue
                  </button>
                </>
              )}
              {gameWasPlayedToday &&(
                <>
                  <h1 className="landing-title">Hi Swiftie</h1>
                  <h2>
                    Great job on today's puzzle!
                    <br />
                    Check out your progress.
                  </h2>
                  <button onClick={showProgressModal} className="button-black">
                    See Stats
                  </button>
                </>
              )}
              <p>
                <b>{displayDate}</b>
                <br />
                No. {gameNo}
                <br />
                Created by Ifat Neumann
                <br />
                Based on the popular game{" "}
                <a
                  href="https://www.nytimes.com/games/wordle/index.html"
                  style={{ color: "black" }}
                >
                  Wordle
                </a>
              </p>
            </div>
          </div>
        </>
      )}

      {(showWordle || showInstructions || showProgress) && (
        <Wordle
          solution={solution}
          split={split}
          album={album}
          showInstructions={showInstructions}
          showProgress={showProgress}
          gameNo={gameNo}
        />
      )}
    </>
  );
}
