import { useState } from "react"

const useWordle = (gameNo, solution, setShaky) => {
  let gameWasPlayedToday, gameData;
  if (localStorage.wordlestruckLastGamePlayed) {
    gameWasPlayedToday =
      JSON.parse(localStorage.wordlestruckLastGamePlayed).gameNo === gameNo
        ? true
        : false;
    gameData = JSON.parse(localStorage.wordlestruckLastGamePlayed);
  }
  let initialGameNo = gameWasPlayedToday?gameData.gameNo:0
  let initialTurn = gameWasPlayedToday?gameData.turn:0
  let initialGuesses = gameWasPlayedToday?gameData.guesses:[...Array(6)]
  let initialHistory = gameWasPlayedToday?gameData.history:[]
  let initialIsCorrect = gameWasPlayedToday?gameData.isCorrect:false
  let initialUsedKeys = gameWasPlayedToday?gameData.usedKeys:{}
  
  const [currentGuess, setCurrentGuess] = useState('')
  const [storageData, setStorageData] = useState({
      gameNo: initialGameNo,
      turn: initialTurn,
      isCorrect: initialIsCorrect,
      guesses: initialGuesses,
      usedKeys: initialUsedKeys,
      history: initialHistory
  })

    const formatGuess = () => {
        let solutionArray = [...solution]
        let formattedGuess = [...currentGuess].map((letter)=>{
            return {key: letter, color: 'grey'}
        })
        // find green
        formattedGuess.forEach((letterObj, index) => {
            if(solutionArray[index] === letterObj.key){
                letterObj.color = 'green'
                // to avoid override in yellow
                solutionArray[index] = null   
            }
        });
        
        // find yellow
        formattedGuess.forEach((letterObj) => {
            if(solutionArray.includes(letterObj.key) && letterObj.color !== 'green'){
                letterObj.color = 'yellow'
                solutionArray[solutionArray.indexOf(letterObj.key)] = null
            }
        });
        return formattedGuess
    }

    const addNewGuess = (formattedGuess) => {
        setCurrentGuess('')
        
        setStorageData((prevStorageData) => {
            let prevTurn = prevStorageData.turn;
            let newTurn = prevTurn + 1;

            let prevStorageGuesses = prevStorageData.guesses;
            let newGuesses = [...prevStorageGuesses]
            newGuesses[prevTurn] = formattedGuess

            let prevUsedKeys = prevStorageData.usedKeys
            let newUsedKeys = {...prevUsedKeys}

            formattedGuess.forEach((letterObj) => {
                const currentColor = newUsedKeys[letterObj.key]

                if(letterObj.color === 'green'){
                    newUsedKeys[letterObj.key] = 'green'
                    return
                }
                if(letterObj.color === 'yellow' && currentColor !== 'green'){
                    newUsedKeys[letterObj.key] = 'yellow'
                    return
                }
                if(letterObj.color === 'grey' && currentColor !== 'green' && currentColor !== 'yellow'){
                    newUsedKeys[letterObj.key] = 'grey'
                    return
                }
            })
            let prevHistory = prevStorageData.history
            let newHistory = [...prevHistory, currentGuess]
            let newIsCorrect = currentGuess === solution ? true:false;
            return{
                gameNo: gameNo,
                turn: newTurn,
                isCorrect: newIsCorrect,
                guesses: newGuesses,
                usedKeys: newUsedKeys,
                history: newHistory
            }
        });

    }
    const handleKeyup = ({ key }) => {
        handleKeyupVirtual(key)
        return
    }
    const handleKeyupVirtual = ( key ) => {
        if (key === 'CapsLock') {
            return;
        }
        if(key === 'Enter'){
            if(storageData.turn>5){
                console.log('You used all your guesses')
                return
            }
            if(storageData.history.includes(currentGuess)){
                setShaky(true)
                return
            }
            if(currentGuess.length!==solution.length){
                console.log(`word must be ${solution.length} chars long`)
                return
            }
            
            const formattedGuess = formatGuess()
            addNewGuess(formattedGuess)
        }
        if(key === 'Backspace'){
            setCurrentGuess((prev) => {
                return prev.slice(0, -1)
            })
            setShaky(false)
            return
        }
        if(/^[A-Za-z]$/.test(key)){
            if(currentGuess.length < solution.length){
                setCurrentGuess((prev) => {
                    return prev + key.toLowerCase();
                })
            }
        }
    }

    return {
      currentGuess,
      handleKeyup,
      handleKeyupVirtual,
      storageData
    };
}

export default useWordle