import React from 'react'
import Row from './Row'

export default function Grid({currentGuess, guesses, turn, length, split, shaky}) {
  let smallGridWidth = 62 * length
  let smallView = smallGridWidth<360
  return (
    <div id="grid-container">
      <div id="grid-div" style={{width: smallView ? `${smallGridWidth}px`:`500px`, height: smallView ? `${smallGridWidth*(6/length)}px`:`360px`, fontSize: length < 6 ? `30px`:'13px'}}>
          {guesses.map((guess, index) => {
              if(turn === index){
                  return <Row key={index} currentGuess={currentGuess} length={length} split={split} shaky={shaky}/>
              }
              return <Row key={index} guess={guess} length={length} split={split}/>
          })}
      </div>
    </div>
  )
}
