function addLeapYearDays(currentYear,isItAfterFebruary) {
  let num_of_leap_years = 0;

  // not including the current year
  for (let year = 1; year < currentYear; year++) {
    // occurs in each year that is an integer multiple of 4
    // (except for years evenly divisible by 100, but not by 400).
    if (year % 4 === 0 && !(year % 100 === 0 && year % 400 !== 0)) {
      num_of_leap_years += 1;
    }
  }

  // checking current year
  let isCurrentYearLeap = (currentYear % 4 === 0 && !(currentYear % 100 === 0 && currentYear % 400 !== 0))
  if (isCurrentYearLeap && isItAfterFebruary) {
    num_of_leap_years++;
  }
  return num_of_leap_years;
}

function num_of_days(date) {
  let monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let fullYearsInDays = (year - 1) * 365;
  let fullMonthsInDays = 0;
  for (let i = 0; i < month -1; i++) {
    fullMonthsInDays += monthDays[i];
  }
  let leapYearDays = addLeapYearDays(year, month > 2);

  let totalDays = fullYearsInDays + fullMonthsInDays + day + leapYearDays;
  return totalDays;
}

function inclusive_num_of_days_from(date) {
  var today = new Date();

  let totalDaysThen = num_of_days(date);
  let totalDaysToday = num_of_days(today);
  
  // to assure we start at 1
  return totalDaysToday - totalDaysThen + 1;
}

const getGameIndexofToday = function () {
  let startingDate = new Date(2024, 2, 23); // YYYY-(MM-1)-DD
  return inclusive_num_of_days_from(startingDate);
};

export default getGameIndexofToday;
