import React from "react";
import Statistics from "./Statistics";

export default function ProgressModal({
  setShowModal,
  solution,
  album,
  gameNo,
  split,
}) {
  let lastGame, gameWasPlayedToday=false;
  if (localStorage.wordlestruckLastGamePlayed) {
    lastGame = JSON.parse(localStorage.wordlestruckLastGamePlayed);
    gameWasPlayedToday = lastGame.gameNo === gameNo && (lastGame.isCorrect || lastGame.turn > 5);
  }

  function shareResult() {
    let turnFromLocalStorage = JSON.parse(localStorage.wordlestruckLastGamePlayed).turn;
    let isCorrectFromLocalStorage = JSON.parse(localStorage.wordlestruckLastGamePlayed).isCorrect;
    let turnIndex = !isCorrectFromLocalStorage && turnFromLocalStorage === 6 ? 'X' : turnFromLocalStorage;
    let squaresString = `Wordlestruck ${gameNo} ${turnIndex}/6\n\n`;
    let guesses = JSON.parse(localStorage.wordlestruckLastGamePlayed).guesses;
    guesses.forEach((formattedGuess) => {
      //some are null
      if (formattedGuess) {
        formattedGuess.forEach((letterObj, index) => {
          if (letterObj.color === "green") {
            squaresString += "🟩";
          } else if (letterObj.color === "yellow") {
            squaresString += "🟨";
          } else {
            squaresString += "⬛";
          }
          //because indexes in split are indexes in which there is a space *after*
          if (split.includes(index)) {
            squaresString += "  ";
          }
        });
        squaresString += "\n";
      }
    });
    navigator.clipboard.writeText(squaresString);
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied!";
  }
  function restartTooptip() {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  }

  return (
    <div className="modal">
      <div className="in-modal">
        <span
          className="close"
          onClick={() => {
            setShowModal(false);
          }}
        >
          &times;
        </span>
        {gameWasPlayedToday && (
          <>
            <p className="solution">
              <b>{solution}</b> from <b>{album}</b>
            </p>
            <div className="horizontal-line"></div>
          </>
        )}
        <Statistics gameNo={gameNo} />

        <div className="horizontal-line"></div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <img
                  className="modal-image"
                  src="linkedin.png"
                  alt="LinkedIn icon"
                />
              </td>
              <td>
                <p className="modal-text">
                  Liked the game? Have ideas for improvements? <br />
                  <a
                    href="https://www.linkedin.com/in/ifatneumann"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black" }}
                  >
                    Come say hi on LinkedIn!
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {gameWasPlayedToday && (
          <>
            <div className="horizontal-line"></div>
            <div className="tooltip">
              <button
                className="restart-button"
                onClick={shareResult}
                onMouseOut={restartTooptip}
              >
                <span className="tooltiptext" id="myTooltip">
                  Copy to clipboard
                </span>
                Share
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
