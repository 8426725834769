import React from 'react'

export default function Row({ guess, currentGuess, length, split, shaky }) {
    let currentClassName = shaky? "row current shaky":"row current"
    if (guess){
        return (
            <div className='row past'>
                {guess.map((char, index) => {
                return (
                    <React.Fragment key={`${index}_seg`}>
                        <div key={`${index}_tile-con`} className={`tile-container-${char.color}`}>
                            <div key={index}>{char.key}</div>
                        </div>
                        {split.includes(index) && (<div key={`${index}_seperator`} className="space-div" style={{display:"display"}}></div>)}
                    </React.Fragment>
                )})}
            </div>
        )
    }
    if(currentGuess){
        let letters = currentGuess.split('')

        return (
            <div className={currentClassName}>
                {letters.map((letter, i)=>{
                return (
                    <React.Fragment key={`${i}_seg`}>
                        <div key={`${i}_tile-con`} className="tile-container-filled">
                            <div key={i}>{letter}</div>
                        </div>
                        {split.includes(i) && (<div key={`${i}_seperator`} className="space-div"></div>)}
                    </React.Fragment>
                )})}

                {[...Array(length-letters.length)].map((_,i) => {
                return (
                    <React.Fragment key={`${i}_seg`}>
                        <div key={`${i}_tile-con`} className="tile-container">
                            <div key={i}></div>
                        </div>
                        {split.includes(i+letters.length) && (<div key={`${i}_seperator`} className="space-div"></div>)}                
                    </React.Fragment>
                )})}
            </div>
        )
    }
    return (
        <div className='row'>
            {[...Array(length)].map((_, i) => {
                return (
                    <React.Fragment key={`${i}_seg`}>
                        <div key={`${i}_tile-con`} className="tile-container">
                            <div key={i}></div>
                        </div>
                        {split.includes(i) && (<div key={`${i}_seperator`} className="space-div"></div>)} 
                    </React.Fragment>
                )})}
        </div>
    )
}
